import React from 'react';
import styles from './Hero.module.css';

interface HeroProps {
  children: React.ReactNode;
  title: string;
}

export default function Hero({ children, title }: HeroProps) {
  return (
    <section className={styles.wrapper}>
      <h2 className={styles.heading}>{title}</h2>
      <div className={styles.content}>{children}</div>
    </section>
  );
}
