import { Link } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import Hero from '../components/Hero';
import Layout from '../components/Layout';

export default function ConfirmationPage() {
  return (
    <Layout>
      <Helmet>
        <title>Wealthie — Contact</title>
      </Helmet>
      <Hero title="Thank you!">
        <p>Your message has been sent. We will get back to you soon.</p>
        <p>
          Return to <Link to="/">homepage</Link>.
        </p>
      </Hero>
    </Layout>
  );
}
